import * as Sentry from '@sentry/react'
import { Environment, environment, inE2E } from './environment'

const env = environment()
if (env !== Environment.development && !inE2E()) {
  Sentry.init({
    dsn: 'https://0304b5a09de4f022f6c2a80b97b64c34@o4506953915564032.ingest.us.sentry.io/4507176853504000',
    environment: env,
    integrations: [
      Sentry.browserTracingIntegration(),
      // Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
